'use client';

import type {
  HasContractsToApproveData,
  InsuranceContractsData,
} from '@monorepo/interfaces';
import { UnleashFlags, useUnleashFlag } from '@monorepo/utils/hooks/unleash';

import type { InsuranceData } from './types';
import ImageIcon from './image.svg';

export const useInsuranceBanner = ({
  fidcData,
  insuranceContracts,
}: {
  fidcData?: HasContractsToApproveData;
  insuranceContracts?: InsuranceContractsData;
}) => {
  const enabledInsurance = useUnleashFlag(
    UnleashFlags.MIS_267_ENABLE_INSURANCE
  );

  return {
    title:
      'Seguro Familiar, um benefício das escolas parceiras isaac!',
    subtitle:
      'Revise seus contratos 2024 para ter acesso ao documento do Seguro.',
    button: 'Saiba mais',
    href: '/seguro-familiar',
    src: ImageIcon.src,
    showBanner:
      enabledInsurance &&
      !!fidcData?.hasContractsToApprove &&
      !!fidcData?.details?.waiting_for_approval?.length &&
      !!insuranceContracts?.insuredContracts?.length,
  } as InsuranceData;
};
