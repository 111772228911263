'use client';

import type { TargetAndTransition } from 'framer-motion';
import React from 'react';
import { motion } from 'framer-motion';
import type { ViewProps } from './types';
const defaultTransition = {
  duration: 0.1,
  type: 'easeInOut'
};
const baseVariant: TargetAndTransition = {
  zIndex: 1000
};
export const View = ({
  children,
  transition = defaultTransition
}: ViewProps) => {
  return <motion.div initial="hidden" animate="enter" exit="exit" key="pageTransition" className="h-full w-full" variants={{
    hidden: {
      opacity: 0,
      transition,
      ...baseVariant
    },
    enter: {
      opacity: 1,
      transition,
      ...baseVariant,
      transitionEnd: {
        position: 'static'
      }
    },
    exit: {
      opacity: 0,
      transition,
      ...baseVariant
    }
  }} data-sentry-element="unknown" data-sentry-component="View" data-sentry-source-file="view.tsx">
      {children}
    </motion.div>;
};