'use client';

import React from 'react';
import { TeachingMaterialStatus } from '@monorepo/interfaces/teachingMaterial/status';
import { useRouter } from '@monorepo/router';
import type { Variant } from '@rootTailwindUi/src/components/Banner/types';
import { Banner } from '@rootTailwindUi/src/components/Banner';
import { useTeachingMaterialBanner } from './useTeachingMaterialBanner';
export const TeachingMaterialBanners = ({
  status
}: {
  status?: TeachingMaterialStatus;
}) => {
  const [showBanner] = React.useState<boolean>(true);
  const router = useRouter();
  const bannerData = useTeachingMaterialBanner(status);
  if (!bannerData.showBanner) return null;
  const {
    title,
    subtitle,
    button,
    src,
    bannerType
  } = bannerData;
  const clickButton = () => {
    router.push('negociacao/material-didatico', {
      useWindowOpen: true
    });
  };
  const variants: Record<string, Variant> = {
    root: bannerType === TeachingMaterialStatus.ALL_PAID ? 'hawkesBlue' : 'white',
    title: bannerType === TeachingMaterialStatus.ALL_PAID ? 'hawkesBlue' : 'gray',
    subtitle: bannerType === TeachingMaterialStatus.ALL_PAID ? 'hawkesBlue' : 'gray'
  };
  if (!showBanner) return null;
  return <Banner.Root variant={variants.root} data-sentry-element="unknown" data-sentry-component="TeachingMaterialBanners" data-sentry-source-file="index.tsx">
      <Banner.Content data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <Banner.Section contentWidth={!src ? 'full' : ''} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <Banner.Title title={title} variant={variants.title} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
          <Banner.Subtitle fontSizeDesktop="small" subtitle={subtitle} variant={variants.subtitle} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
        </Banner.Section>
        {src ? <Banner.Image src={src} smallImage /> : null}
      </Banner.Content>
      {button && <Banner.Footer>
          <Banner.Button label={button} onClick={clickButton} variant={'darkBlue'} />
        </Banner.Footer>}
    </Banner.Root>;
};