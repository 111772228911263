export enum TeachingMaterialStatus {
  ALL_PAID = 'all-paid',
  AT_LEAST_ONE_OPEN = 'at-least-one-open',
  NO_HYBRID_MODEL_TEACHING_MATERIAL = 'no-hybrid-model-teaching-material',
}

export type StatusTeachingMaterialType =
  | 'all-paid'
  | 'at-least-one-open'
  | 'no-hybrid-model-teaching-material';
