'use client';

import { TeachingMaterialStatus } from '@monorepo/interfaces/teachingMaterial/status';

import type { BannerData } from './types';
import OpenTeachingMaterialImage from './open-teaching-material.svg';
import PaidTeachingMaterialImage from './paid-teaching-material.svg';

const copy: Record<
  Exclude<string, undefined>,
  Omit<BannerData, 'showBanner'>
> = {
  [TeachingMaterialStatus.ALL_PAID]: {
    title: 'Compra do material didático concluída',
    subtitle:
      'Seu pagamento foi confirmado com sucesso. \n Sua escola irá te avisar quando for a hora de retirar o material.',
    bannerType: 'all-paid',
    src: PaidTeachingMaterialImage,
    showBanner: true,
  },
  [TeachingMaterialStatus.AT_LEAST_ONE_OPEN]: {
    title: 'Garanta o material didático do ano',
    subtitle: 'Pague em até 12x e retire o material na escola',
    button: 'Pagar fatura',
    bannerType: 'at-least-one-open',
    src: OpenTeachingMaterialImage,
    showBanner: true,
  },
  [TeachingMaterialStatus.NO_HYBRID_MODEL_TEACHING_MATERIAL]: {
    showBanner: false,
  },
};

export const useTeachingMaterialBanner = (
  status: TeachingMaterialStatus | undefined
) => {
  if (!status)
    return copy[
      TeachingMaterialStatus.NO_HYBRID_MODEL_TEACHING_MATERIAL
    ] as BannerData;

  const bannerData = copy[status] as BannerData;

  return bannerData;
};
