'use client';

import { Badge } from '@gravity/badge';
import { Text } from '@gravity/text';
import { ChevronRight } from 'lucide-react';
import { CardVariations } from '@srcTailwindUi/components/CardVariations';
import { Skeleton } from '@srcTailwindUi/components/Skeleton';
import { cn } from '@srcTailwindUi/lib/utils';
import type { InstallmentCardProps } from '../types';
import { useInstallmentCard } from '../useInstallmentCard';
export const InstallmentCardEntry = ({
  loading = false,
  data,
  onClick
}: InstallmentCardProps) => {
  const {
    dateColor,
    amountColor,
    showInfoStrike,
    valueWithoutDueDiscount,
    value,
    formattedDate,
    cardTitle,
    type,
    subStatus,
    cardVariant,
    cardTitleColor,
    showWithDiscountBadge
  } = useInstallmentCard(data);
  if (loading) return <CardVariations variant={cardVariant} onClick={onClick}>
        <div className="grid w-full auto-rows-min grid-cols-[2fr_1fr_0.05fr] grid-rows-[fit-content(100%)] gap-x-3 gap-y-2" data-testid="installment-card-entry-grid-container-skeleton">
          <div className="col-start-1 col-end-4 row-start-1 row-end-2 h-5">
            <Skeleton className="h-full w-[180px] bg-gray-60" />
            {subStatus ? <Skeleton className="h-full w-[180px] bg-gray-60" /> : null}
          </div>
          <div className="col-start-1 col-end-3 row-start-2 row-end-3 h-5">
            <Skeleton className="h-full w-[180px] bg-gray-60" />
          </div>
          <div className="col-start-2 col-end-3 row-start-2 row-end-3 h-5">
            <Skeleton className="h-full w-[106px] bg-gray-60" />
            {showInfoStrike ? <Skeleton className="h-full w-[80px] bg-gray-60" /> : null}
          </div>
          <div className="col-start-3 col-end-4 row-start-2 row-end-3 h-5">
            <Skeleton className="h-full w-[14px] bg-gray-60" />
          </div>
          <div className="col-start-1 col-end-2 row-start-3 row-end-4 h-5">
            <Skeleton className="h-full w-[152px] bg-gray-60" />
          </div>
          <div className="col-start-2 col-end-3 row-start-3 row-end-4 h-5">
            <Skeleton className="h-full w-[106px] bg-gray-60" />
          </div>
        </div>
      </CardVariations>;
  return <CardVariations variant={cardVariant} onClick={onClick} data-sentry-element="CardVariations" data-sentry-component="InstallmentCardEntry" data-sentry-source-file="index.tsx">
      <div className="grid w-full auto-rows-min grid-cols-[1fr_1fr_0.05fr] grid-rows-[fit-content(100%)] gap-x-3 gap-y-2" data-testid="installment-card-entry-grid-container">
        <div className="col-start-1 col-end-4 row-start-1 row-end-2 text-left">
          <Text variant="body-2-medium" className={cn('tracking-tight text-gray-600 sm:max-w-none', cardTitleColor ?? '')} data-sentry-element="Text" data-sentry-source-file="index.tsx">
            {type}
          </Text>
          {subStatus && <div>
              <Text variant="body-2-regular" className="text-gray-600 sm:max-w-none">
                {subStatus}
              </Text>
            </div>}
        </div>
        {showWithDiscountBadge && <div className="col-start-2 col-end-4 row-start-1 row-end-1 text-right">
            <Badge variant="soft" color="success">
              Com desconto
            </Badge>
          </div>}
        <div className="col-start-1 col-end-3 row-start-2 row-end-3">
          <Text variant="subtitle-medium" className={cn('line-clamp-2 max-w-[10.625rem] text-ellipsis text-left text-gray-800 sm:max-w-none')} data-testid="installment-card-entry-product" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            {cardTitle}
          </Text>
        </div>
        <div className="col-start-2 col-end-3 row-start-2 row-end-3 text-right">
          <Text variant="subtitle-medium" className={cn('text-right text-gray-600 sm:max-w-none', amountColor ?? '')} data-testid="installment-card-entry-value" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            {value}
          </Text>
          {showInfoStrike ? <s className="m-0 text-right text-xs font-normal leading-[1.5] tracking-[0.4px] text-gray-300 sm:max-w-none" data-testid="installment-card-entry-value-without-discount">
              {valueWithoutDueDiscount}
            </s> : null}
        </div>
        <div className="col-start-3 col-end-4 row-start-2 row-end-3">
          <div data-testid={'unpaidAgreement'}>
            {<ChevronRight className="h-5 w-5 text-gray-800" />}
          </div>
        </div>
        <div className="col-start-1 col-end-2 row-start-3 row-end-4 self-center">
          <Text variant="body-2-regular" className={cn('line-clamp-1 max-w-[9.5rem] text-ellipsis text-left text-gray-600 sm:max-w-none')} data-testid="installment-card-entry-student" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            {data?.studentName}
          </Text>
        </div>
        <div className="col-start-2 col-end-3 row-start-3 row-end-4 text-right">
          <Text variant="body-2-regular" className={cn('text-gray-600 sm:max-w-none', dateColor ?? '')} data-testid="installment-card-entry-date" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            {formattedDate}
          </Text>
        </div>
      </div>
    </CardVariations>;
};