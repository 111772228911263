import type { PropsWithChildren } from 'react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Dialog, DialogContent, DialogHeader } from '@srcTailwindUi/components/Dialog';
import { Drawer, DrawerContent } from '@srcTailwindUi/components/Drawer';
import { cn } from '@srcTailwindUi/lib/utils';
import type { ViewProps } from './types';
export const View = ({
  open,
  onClose,
  headerComponent,
  dragIndicatorBgColor,
  allowClosing,
  absoluteCloseButton,
  isModal,
  renderCloseButton,
  children
}: PropsWithChildren<ViewProps>) => {
  if (isModal) {
    return <Dialog open={open} onOpenChange={value => {
      if (value === false && allowClosing) onClose();
    }}>
        <DialogContent>
          {headerComponent}
          {renderCloseButton ? <DialogHeader className={cn('z-[1] flex justify-end pb-3', absoluteCloseButton ? 'absolute right-6 top-6' : '')}>
              <button onClick={allowClosing ? onClose : undefined} className="outline-none" data-testid="close-button">
                <Icon path={mdiClose} className="h-5 w-5 text-gray-600" />
              </button>
            </DialogHeader> : null}
          <div className="flex-1 p-6">{children}</div>
        </DialogContent>
      </Dialog>;
  }
  return <Drawer open={open} onOpenChange={value => {
    if (value === false && allowClosing) onClose();
  }} dismissible={allowClosing} data-sentry-element="Drawer" data-sentry-component="View" data-sentry-source-file="view.tsx">
      <DrawerContent dragIndicatorBgColor={dragIndicatorBgColor} showDragIndicator={renderCloseButton} data-sentry-element="DrawerContent" data-sentry-source-file="view.tsx">
        {headerComponent}
        <div className="flex-1 p-6">{children}</div>
      </DrawerContent>
    </Drawer>;
};