'use client';

import type React from 'react';
import { useWindowSize } from '@monorepo/utils/hooks/useWindowSize';
import type { ResponsiveDialogProps } from './types';
import { View } from './view';
export function ResponsiveDialog({
  open,
  onClose,
  headerComponent,
  dragIndicatorBgColor,
  allowClosing = true,
  absoluteCloseButton,
  showCloseButton = true,
  children
}: React.PropsWithChildren<ResponsiveDialogProps>) {
  const {
    width
  } = useWindowSize();
  const isModal = (width ?? 0) > 635;
  const renderCloseButton = showCloseButton && allowClosing;
  if (!width) return null;
  return <View open={open} onClose={onClose} headerComponent={headerComponent} dragIndicatorBgColor={dragIndicatorBgColor} allowClosing={allowClosing} absoluteCloseButton={absoluteCloseButton} isModal={isModal} renderCloseButton={renderCloseButton} data-sentry-element="View" data-sentry-component="ResponsiveDialog" data-sentry-source-file="index.tsx">
      {children}
    </View>;
}