'use client';

import { useMemo } from 'react';
import { CreditCard } from 'lucide-react';

import type { GetInstallmentV2RouteResponse } from '@monorepo/interfaces';
import { useSendEvents } from '@monorepo/events/src/negotiation';
import { EventScope, NegotiationEvents } from '@monorepo/events/src/types';
import { useRouter } from '@monorepo/router';
import {
  nextInstallmentIdToPay,
  nextInstallmentToPay,
  orderInstallments,
} from '@monorepo/utils/dashboardHelpers';
import { diffDays } from '@monorepo/utils/dateHelpers';

import type { CardButtonProps } from '@srcTailwindUi/components/CardButton/types';
import { BarCodeCustomIcon } from '@srcTailwindUi/components/CardButton';

import { useGreetingInvoiceNegotiation } from '../../useGreetingInvoiceNegotiation';

type LabeledCardButtonProps = CardButtonProps & {
  label: string;
};

export const useNegotiationButtons = ({
  installmentsData,
}: {
  installmentsData?: GetInstallmentV2RouteResponse;
}) => {
  const router = useRouter();
  const { sendButtonClickEvent } = useSendEvents(EventScope.HomePage);

  const { enrollments, tuitions } = useGreetingInvoiceNegotiation({
    installmentsData,
  });

  const {
    overdues: overdueList,
    opens: openList,
    opensToday: openTodayList,
  } = tuitions;

  const {
    overdues: overdueEnrollmentList,
    opens: openEnrollmentList,
    opensToday: openTodayEnrollmentList,
  } = enrollments;

  const overdueNotExpiredList = overdueEnrollmentList.filter(
    ({ expired }) => !expired
  );

  const installments_ids = overdueList.map((item) => item.id);

  const nextIdToPay = useMemo(
    () => nextInstallmentIdToPay(overdueList, openList, openTodayList),
    [overdueList, openList, openTodayList]
  );

  const invoiceDate = useMemo(() => {
    if (overdueList.length === 1) {
      return overdueList[0]?.dueDate;
    }
    const installments = openTodayList.length === 1 ? openTodayList : openList;
    return orderInstallments(installments, 'dueDate', 'asc')[0]?.dueDate;
  }, [overdueList, openTodayList, openList]);

  const onTime = useMemo(() => {
    return invoiceDate ? diffDays(invoiceDate) >= 0 : false;
  }, [invoiceDate]);

  const nextInstallmentEnrollmentToPay = useMemo(
    () =>
      nextInstallmentToPay(
        overdueNotExpiredList,
        openEnrollmentList,
        openTodayEnrollmentList
      ),
    [overdueNotExpiredList, openEnrollmentList, openTodayEnrollmentList]
  );

  const nextIsEnrollment =
    nextInstallmentEnrollmentToPay?.dueDate &&
    diffDays(nextInstallmentEnrollmentToPay.dueDate) < diffDays(invoiceDate);

  const hrefInvoiceClick = (link?: string) => {
    if (link) router.push(link);
  };

  const isOverdue = overdueList.length > 0;

  const cardButtonsOverdue = (cardButtons: LabeledCardButtonProps[]) => {
    const { event, data, link } =
      overdueList.length > 1
        ? {
            event: NegotiationEvents.PAY_ALL_OVERDUE_DASHBOARD_CARD_BUTTON,
            data: {
              name: 'Negociar todas as parcelas',
              installments_ids: installments_ids,
            },
            link: '/negociacao/parcelas-vencidas',
          }
        : {
            event: NegotiationEvents.CLICK_PAY_BILL_OVERDUE,
            data: {
              name: 'Negociar parcela',
              installment_id: nextIdToPay,
            },
            link: `/negociacao/parcela/${nextIdToPay}`,
          };

    if (overdueList.length === 1) {
      cardButtons.push({
        label: 'pay-overdue-installment',
        Icon: CreditCard,
        text: 'Negociar parcela',
        onClick: () => {
          sendButtonClickEvent({
            event: NegotiationEvents.CLICK_PAY_BILL_OVERDUE,
            data: {
              name: 'Negociar parcela',
              installment_id: nextIdToPay,
            },
          });
          hrefInvoiceClick(link);
        },
      });
      return { cardButtons };
    }

    const onClick = () => {
      sendButtonClickEvent({
        event,
        data,
      });
      hrefInvoiceClick(link);
    };

    cardButtons.push({
      label: 'pay-overdue-installments',
      Icon: CreditCard,
      text: 'Negociar parcelas',
      onClick,
    });
    return { cardButtons };
  };

  const cardButtonsOndue = (cardButtons: LabeledCardButtonProps[]) => {
    const cardButtonProps = {
      label: 'pay-due-installment',
      Icon: BarCodeCustomIcon,
      text: 'Pagar próxima parcela',
      onClick: () => {
        sendButtonClickEvent({
          event: NegotiationEvents.ANTICIPATE_BILL,
          data: {
            name: 'Pagar proxima parcela',
            installment_id: nextIdToPay,
          },
        });
        return hrefInvoiceClick(`/negociacao/parcela/${nextIdToPay}`);
      },
    };

    if (nextIsEnrollment) {
      cardButtons.push({
        ...cardButtonProps,
        onClick: () => {
          if (diffDays(nextInstallmentEnrollmentToPay.dueDate) > 0) {
            sendButtonClickEvent({
              event: NegotiationEvents.ANTICIPATE_BILL,
              data: {
                name: 'Pagar proxima parcela',
                installment_id: nextInstallmentEnrollmentToPay?.id,
              },
            });
          } else if (diffDays(nextInstallmentEnrollmentToPay.dueDate) < 0) {
            sendButtonClickEvent({
              event: NegotiationEvents.CLICK_PAY_BILL_OVERDUE,
              data: {
                name: 'Negociar parcela',
                installment_id: nextInstallmentEnrollmentToPay?.id,
              },
            });
          }
          hrefInvoiceClick(
            `/negociacao/parcela/${nextInstallmentEnrollmentToPay?.id}`
          );
        },
      });
      return { cardButtons };
    }

    if (onTime) {
      cardButtons.push(cardButtonProps);
      return { cardButtons };
    }

    return { cardButtons };
  };

  const verifyConditions = () => {
    const cardButtons: LabeledCardButtonProps[] = [];

    if (isOverdue) {
      return cardButtonsOverdue(cardButtons);
    }

    return cardButtonsOndue(cardButtons);
  };

  const { cardButtons } = verifyConditions();

  return { cardButtons };
};
