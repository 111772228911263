import { Text } from '@gravity/text';
import { Trans } from 'next-i18next';
import { Badge } from '@srcTailwindUi/components/Badge';
import { cn } from '@srcTailwindUi/lib/utils';
import type { CardButtonProps } from './types';
export const View = ({
  chipText,
  chipVariant,
  withShadow,
  disabled,
  loading,
  Icon,
  onClick,
  text,
  i18nKey
}: CardButtonProps) => <button className={cn('flex h-28 w-32 animate-fadeIn cursor-pointer flex-col rounded-2xl border-[1px] border-solid border-gray-100 bg-white p-4 text-start hover:border-blue-700 hover:bg-blue-200 disabled:cursor-default disabled:bg-gray-50 disabled:hover:border-gray-100 disabled:hover:bg-gray-50', withShadow ? 'shadow-cardButton' : '', loading ? 'pointer-events-none' : 'pointer-events-auto')} onClick={onClick} data-testid="card-button" data-sentry-component="View" data-sentry-source-file="view.tsx">
    <div className="flex items-center justify-between">
      {Icon ? <Icon className="text-blue-600 disabled:text-gray-200" /> : null}
      {chipText ? <Badge variant={chipVariant} data-testid="card-button-chip">
          {chipText}
        </Badge> : null}
    </div>
    {text && <Text variant="body-2-regular" className={cn('mt-2 block text-gray-800', disabled ? 'text-gray-300' : '')}>
        {text}
      </Text>}
    {i18nKey && <Text variant="body-2-regular" className={cn('mt-2 block text-gray-800', disabled ? 'text-gray-300' : '')}>
        <Trans>{i18nKey}</Trans>
      </Text>}
  </button>;