'use client';

import { Text } from '@gravity/text';
import { mdiAlert, mdiAlertCircle, mdiCheckCircle, mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import { Trans } from 'next-i18next';
import { cn } from '@srcTailwindUi/lib/utils';
import type { ViewProps } from './types';
import { notificationVariants } from './variants';
const icons = {
  success: <Icon path={mdiCheckCircle} className="h-6 w-6 text-green-700" data-testid="success-icon" />,
  warning: <Icon path={mdiAlert} className="h-6 w-6 text-yellow-700" data-testid="warning-icon" />,
  error: <Icon path={mdiAlertCircle} className="h-6 w-6 text-coral-700" data-testid="error-icon" />,
  information: <Icon path={mdiInformation} className="h-6 w-6 text-blue-700" data-testid="information-icon" />,
  disabled: <Icon path={mdiInformation} className="h-6 w-6 text-gray-300" data-testid="disabled-icon" />,
  notice: <Icon path={mdiAlert} className="h-6 w-6 text-yellow-700" data-testid="notice-icon" />
};
export const View = ({
  variant,
  description,
  title
}: ViewProps) => {
  return <div data-testid="notification-container" className={cn(notificationVariants({
    variant
  }))} data-sentry-component="View" data-sentry-source-file="view.tsx">
      {icons[variant]}

      <div className="flex flex-1 flex-col justify-center">
        {!!title && <Text variant="body-2-medium" className="text-gray-800">
            <Trans>{title}</Trans>
          </Text>}
        <Text variant="body-2-regular" className="text-gray-800" data-sentry-element="Text" data-sentry-source-file="view.tsx">
          <Trans data-sentry-element="Trans" data-sentry-source-file="view.tsx">{description}</Trans>
        </Text>
      </div>
    </div>;
};