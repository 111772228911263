'use client';

import { motion, useScroll } from 'framer-motion';
import type { ProgressBarProps } from './types';
import { useAnimation } from './animation';
const View = ({
  step,
  totalSteps,
  animate,
  scrollable,
  fullWidth = false,
  scrollRef
}: ProgressBarProps) => {
  const stepperStartWidth = (fullWidth ? 100 : 90) * (step - 1) / totalSteps;
  const stepperFinalWidth = (fullWidth ? 100 : 90) * step / totalSteps;
  const {
    scrollY
  } = useScroll({
    target: scrollRef
  });
  const {
    y
  } = useAnimation(scrollY, scrollable, animate);
  return <motion.div className="fixed left-auto right-0 top-0 z-[1000] mt-14 flex w-full items-center justify-center bg-white shadow-none" style={{
    y
  }} data-sentry-element="unknown" data-sentry-component="View" data-sentry-source-file="view.tsx">
      <motion.div className="flex h-full w-full max-w-[635px] flex-col justify-center" data-sentry-element="unknown" data-sentry-source-file="view.tsx">
        <div className="h-1 w-full bg-blue-100">
          <motion.div className="h-1 bg-blue-600" style={{
          width: `${stepperStartWidth}%`
        }} animate={{
          width: `${stepperFinalWidth}%`
        }} transition={{
          duration: 1
        }} data-sentry-element="unknown" data-sentry-source-file="view.tsx" />
        </div>
      </motion.div>
    </motion.div>;
};
export default View;