'use client';

import type { CardButtonProps } from './types';
import { View } from './view';
export const BarCodeCustomIcon = () => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="BarCodeCustomIcon" data-sentry-source-file="index.tsx">
      <path d="M0.833008 15.8337V4.16699H2.60384V15.8337H0.833008ZM3.33301 15.8337V4.16699H4.99967V15.8337H3.33301ZM5.83301 15.8337V4.16699H6.66634V15.8337H5.83301ZM8.33301 15.8337V4.16699H9.99968V15.8337H8.33301ZM10.833 15.8337V4.16699H13.333V15.8337H10.833ZM14.1663 15.8337V4.16699H14.9997V15.8337H14.1663ZM16.6663 15.8337V4.16699H19.1663V15.8337H16.6663Z" fill="#3D4ED7" data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
};
export const CardButton = ({
  chipText,
  chipVariant = 'green',
  withShadow = false,
  disabled = false,
  loading = false,
  Icon,
  onClick,
  text,
  i18nKey
}: CardButtonProps) => {
  const handleClick = () => {
    if (!disabled && !loading) onClick();
  };
  return <View onClick={handleClick} chipText={chipText} chipVariant={chipVariant} withShadow={withShadow} Icon={Icon} text={text} i18nKey={i18nKey} disabled={disabled} loading={loading} data-sentry-element="View" data-sentry-component="CardButton" data-sentry-source-file="index.tsx" />;
};