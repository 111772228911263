import { cva } from 'class-variance-authority';

export const notificationVariants = cva(
  'flex h-fit items-center gap-3 rounded-2 p-5',
  {
    variants: {
      variant: {
        success: 'bg-green-200',
        warning: 'bg-yellow-200',
        error: 'bg-coral-200',
        information: 'bg-blue-100',
        disabled: 'bg-gray-50',
        notice: 'bg-yellow-100',
      },
    },
  }
);
